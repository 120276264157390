









import Vue from 'vue'
import IntermediateHeader from '@/components/Brand/IntermediateHeader.vue'
import BrandVideo from '@/components/Brand/BrandVideo.vue'
import Component from 'vue-class-component'

@Component({
  components: {
    IntermediateHeader,
    BrandVideo
  }
})
export default class VideoDetails extends Vue {}
